@import "./vars";


@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
}

/*Border Component END*/

ul{
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  background: var(--color-2);
}

.knom_Social{
  word-wrap: 100%;
  justify-content: center;
  margin-bottom: 10px;
}

.knom_Site{
  display: flex;
}

.main-awesome_input{
  width: 100%;
&-content{
  width: 100%;
  height: 100%;
  background: none;
  font-size: 20px;
  color: var(--color-4);
  border: none;
  &:hover,&:focus, &:active {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
  &::placeholder{
    font-weight: 700;
    color: var(--color-4);
  }
}
}


.knom_letter{
  margin-bottom: 30px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--color-7);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  &__link{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: var(--color-2);
    color: var(--color-4);
    font-size: 17px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 400;
    &:hover{
      background: var(--color-1);
      font-weight: 700;
      border: 1px solid var(--color-4);
      font-size: 18px;
    }
  }
  &__heading{
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
  }
  &__title{
    margin: 0;
    display: flex;
    color: var(--color-1);
    font-size: 32px;
  }
  &__logo{
    margin-right: 10px;
    width: 45px;
    height: 45px;
    stroke: var(--color-1);
  }
}

.knom_mobile__button {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 9999;
  flex-direction: column;
  &-line{
      margin-bottom: 5px;
      margin-top: 5px;
      display: block;
      position: relative;
      width: 40px;
      height: 4px;
      border-radius: 10px;
      background-color: var(--color-1);
  }
}
.knom_header_logo{
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.knom_header_logo-logo{

  width: 60px;
  height: 60px;
}

.knom_Container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

.knom_sotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.knom_header{
  margin-bottom: 60px;
  border-bottom: 3px solid var(--color-1);
  padding: 30px 0;
  background: var(--color-3);
  &_menu{
    display: flex;
    column-gap: 35px;
  }
  &_menu a{
    color: var(--color-4);
    display: flex;
    align-items: center;
    font-size: 26px;
    letter-spacing: 1.2px;
    &:hover{
      color: var(--color-1);
      font-weight: 700;
    }
   
  }
  &_inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_logo-link{
    font-size: 37px;
    font-weight: 900;
    color: var(--color-1);
  }
}

.knom_Wrapper{
  margin-right: 40px;
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  border-right: 3px solid var(--color-1);
  padding-right: 20px;
}

.knom_Popular{
  margin-bottom: 30px;
  padding: 0 15px;
  flex-direction: column;
  display: flex;
  & svg{
    width: 60px;
    height: 60px;
  }
  &__box{
    flex-wrap: wrap;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
    &::after{
      position: absolute;
      width: 100%;
    }
  }
  &_title{
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 700;
    color: var(--color-4);
    margin: 0;
    font-size: 34px;
    margin-left: 15px;
  }
}

.ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__mobile-popup-badge{
  background-color: var(--color-7) !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.ya-share2__list.ya-share2__list_direction_horizontal{
  display: flex !important;  
  justify-content: center;
}

.knom_Sidebar{
  box-sizing: border-box;
}

.pages a, .pages span{
  color: #fff;
  width: 100%;
  justify-content: flex-end;
  margin-right: 5px;
  font-weight: 700;
}

.pages{
  margin-top: 30px;
  text-align: end;
  font-size: 20px;
  width: 100%;
}

.knom_result-of-search:hover{
  text-transform: uppercase;
  .knom_result-of-search_link{
    color: #fff;
  }
}

.active{
  display: flex !important;
}

.knom_result-of-search{
  padding: 15px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: var(--color-1);
  }
  &_link{
    font-size: 18px;
    color: var(--color-1);
    margin-left: 15px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.knom{
  display: flex;
  flex-direction: column;
  p{
    font-size: 28px;
    text-align: center;
    color: #fff;
  }
  &_card_artist, &_card_tracklink{
    overflow: hidden;
    max-height: 30px;
  }
  &_card{
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all .3s ease;
    background: var(--color-7);
    border: 3px solid var(--color-1);
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px;
    &_artist{
      margin-right: 5px;
    }
     &:hover{
      background-color: var(--color-2);
      .knom_card_artist, .knom_card_time, .knom_card_tracklink{
        color: var(--color-1);
        width: 100%;
      }
      .knom_card_download svg{
        fill: var(--color-1);
      }
     }
  
  }
  &_card.pausing a.knom_card_link::before{
    content: url('../images/card-play.svg');
  }
  &_card.plays a.knom_card_link::before{
    content: url('../images/card-pause.svg');
  }
  &_card_like{
    height: 30px;
  }
  &_card .start {
    cursor: pointer;
} 
  &_card-subtext{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 110px;
  }
  &_card_name{
    height: 100%;
    margin: 0 0 0 10px;
    line-height: normal;
    width: 100%;
    flex-direction: column;
    display: flex;
  }
  &_card_time{
    font-size: 19px;
    color: var(--color-4);
  }
  &_card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }
  &_card_artist, &_card_tracklink{
    color: var(--color-4);
  }
  &_card_download{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    svg{
      fill: #fff;
      width: 60px;
      height: 60px;
    }
  }
  &_card_link{
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 10px;
    color: var(--white-color);

    &::before {
      content: url(../images/card-play.svg);
      width: 60px;
      height: 60px;
      border: 3px solid var(--color-1);
      border-radius: 60px;
    }
  }
}

.knom_music{
  &__title{
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }
  &__title-heading{
    margin: 0;
    color: var(--color-1);
    font-size: 32px;
  }
  &__title-logo{
    width: 45px;
    height: 45px;
    fill: var(--color-1);
    margin-right: 10px;
  }
}

.submit {
  cursor: pointer;
    transition: all .2s ease;
    border: none;
    background: 0 0;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
  svg {
    width: 38px;
    height: 38px;
  }
}

.dur {
  margin-right: 0;
  position: relative;
  line-height: normal;
}

.title {
  flex-direction: column;
  display: flex;
  align-items: center;
  font-weight: 400;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
  font-size: 20px;
  margin-right: 20px;
  height: 100%;
  width: calc(100% - 135px);
  align-items: start;
}

.knom_logo-link{
  font-size: 35px;
  font-weight: 700;
  color: var(--color-1);
}

.knom_footer{
  margin-top: 10px;
  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--color-1);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.knom_player-link{
  font-size: 30px;
  color: #fff;
}

.knom_story_track-title{
  width: calc(100% - 35px);
  word-wrap: break-word;
}

.knom_story{
  &_heading{
    display: flex;
    align-items: center;
    color: var(--color-1);
    font-size: 30px;
    column-gap: 20px;
  }
  &_track{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &_track-link{
    color: var(--color-1);
    margin-bottom: 20px;
    display: flex;
    font-size: 25px;
    font-weight: 700;
    &:hover{
      border-bottom: 2px solid var(--color-1);
      .knom_story_track-title{
        color: var(--color-4);
        padding-bottom: 10px;
      }
      
    }
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--white-color);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.knom_Site{
  border-bottom: 3px solid var(--color-1);
}

.knom_description{
  width: 450px;
  font-size: 22px;
  &_content{
    justify-content: center;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 25px;
  }
  &_icon{
    font-size: 27px;
    margin-right: 10px;
  }
  &_title{
    display: flex;
    text-align: center;
    } 
  }

.knom_boxline{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.knom_copyright_box{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 200px;
  margin-top: 20px;
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    color: var(--color-1);
    display: flex;
    font-size: 28px;
    align-items: center;
    a{
      color: var(--color-1);
      margin-left: 10px;
      font-weight: 700;
      font-size: 25px;
      margin-top: 2px;
    }
  }
  &-link{
    color: var(--color-14);
  }
}

.knom_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--color-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top p{
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--white-color);
    text-align: center;
  }
}

 .knom_sotial-network-vk {
  &_btn{
    border-radius: 20px;
    height: 60px;
    border: 1px solid var(--color-1);
    color: var(--color-1);
    background-color: var(--color-11);
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    &::placeholder{
      color: var(--color-1);
    }
  }
  &_link{
    border-radius: 20px;
    font-size: 22px;
    font-weight: 700;
    border: 1px solid var(--color-1);
    color: var(--color-1);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    height: 60px;
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--color-11);
      z-index: -1;
      -webkit-transition: width 150ms ease-in-out;
      transition: width 150ms ease-in-out;
    }
    &:hover {
      color: var(--color-14);
      background-color: var(--color-1);
    }
    &:hover::after{
      width: 110%;
    }
  }
  &_input{
    padding: 0 15px;
    margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
  &_content{
    line-height: 2.2;
    font-size: 19px;
    color: var(--color-14);
    background: var(--color-2);
    padding: 20px;
    font-weight: 700;
    border-radius: 25px;
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--color-1);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--color-1);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.knom_letter__story-his{
  width: 70px;
  height: 70px;
}

.knom_genre{
  &-list{
    display: flex;
    flex-direction: column;
  }
  &-item{
    padding: 5px;
    display: flex;
    &_link{
      padding-bottom: 10px;
      font-size: 25px;
      color: var(--color-4);
    }
    &:hover{
      .knom_genre-item_link{
        margin-bottom: 10px;
        color: var(--color-1);
        border-spacing: 10px;
        border-bottom: 2px solid var(--color-4);
      }
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--color-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

#main-awesome_search__form {
    display: flex;
    align-items: center;
    border: 2px solid var(--color-1);
    margin-bottom: 60px;
    padding: 15px 20px;
    border-radius: 20px;
    background: var(--color-7);
}


.pleer {
    z-index: 99999;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 20px;
    background: var(--color-2);
    transition: all .6s ease;
}



.knom_volume {
  margin-left: 10px;
  cursor: pointer;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 20px;
    background: var(--color-1);
    margin-top: 0;
    border-radius: 5px;
    z-index: 202;
    position: relative;
  .knom_volume__line {
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 25%;
    height: 20px;
    float: left;
    -webkit-transition: width 0.7s ease;
    transition: width 0.7s ease;
    background: #fff;
    border-radius: 5px;
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

a.play {
  color: var(--white-color);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}



.play,.pause{
    margin: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 75px;
    height: 75px;
  }
}
.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 75px;
    height: 75px;
  }
}

.knom_track-central_left-btn,
.knom_track-central_right-btn{
  svg{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: var(--color-1);
  }
}

.knom_track-central_right-btn svg{
  transform: rotate(180deg);
}

.uil{
  cursor: pointer;
}

.knom_track-central{
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-right: 30px;
}

.progressBarContainer-playBar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--color-2);
  color: var(--color-2);
}

.progressBarContainer--downloadBar{
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar{
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 3px;
  top: 2px;
  display: inline-block;
  height: 16px;
  background: var(--color-4);
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-color);
    border: 2px solid #061161;
    display: none;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0;
  margin-top: 20px;
}

.track__track-timebox {
  position: absolute;
    width: 100%;
    height: 20px;
    background: var(--color-5);
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.knom_zero-time {
  ffont-size: 16px;
  color: var(--color-3);
  font-size: 700;
}

.knom_timeBox {
  font-size: 16px;
  color: var(--color-3);
  font-size: 700;
}

.knom_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    font-weight: 700;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 22px;
    margin-right: 30px;
    margin-left: 0;
    color: #fff;
}

.knom_mute-box {
  width: 250px;
  justify-content: flex-end;
  display: flex;
  height: 100%;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--white-color);
    }
  }

  &__muteUnmute{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}


.knom_time{
  &_space{
    font-weight: 700;
    margin: 0 5px;
    margin-bottom: 5px;
  }
    display: flex;
    color: var(--color-2);
    position: absolute;
    left: 10px;
    z-index: 2;
    top: 0;
    bottom: 0;
    align-items: center;
}

.knom_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .player__Collapse{
    display: none;
  }
}

.knom_playerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.knom_track-info{
  align-items: center;
  display: flex;
}

.knom_player-title{
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

.knom_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--color-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  padding: 2px 5px 2px 0;
  width: 40px;
  height: 24px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.knom_mute,
.knom_unmute{
  svg{
    position: relative;
    display: inline-block;
  }
}
.knom_mute{
  fill: var(--color-1);
}
.knom_unmute{
  fill: none;
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.knom_shareblock{
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--color-11);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--color-2);
    b {
      border: 4px solid var(--color-1);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-11);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--color-1);
      border-bottom: 4px solid var(--white-color);
    }
    span {
      width: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;    
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      color: var(--color-1);
      font-size: 25px;
      background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 10px 0;
      padding: 0 10px 0 25px;
      &.vk {
        background-position: 0 4px;
      }
      &.tw {
        background-position: 0 -56px;
      }
      &.ok {
        background-position: 0 -86px;
      }
      &.mm {
        background-position: 0 -116px;
      }
      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}






.knom_Social_vk {
  height: 60px;
  background-color: var(--color-3);
  border-radius: 30px;
  margin-bottom: 30px;
  }


/* 
========================
      BUTTON ONE
========================
*/
.knom_Download_vk:hover{
  background-color: rgba(255,255,255,0.4);
  transition: all 0.7s;
  color: var(--color-1);
}
.knom_Download_vk {
  line-height: 60px;
  height: 60px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  color: #fff;
  transition: all .3s;
  position: relative;
  border-radius: 30px;
  border: 2px solid var(--color-1);
}
.knom_Download_vk span {
	transition: all 0.3s;
  font-weight: 700;
  font-size: 22px;
}
.knom_Download_vk::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: rgba(255,255,255,0.5);
	border-bottom-color: rgba(255,255,255,0.5);
	transform: scale(0.1, 1);
  letter-spacing: 2px;
}






.knom_active__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--color-1) !important;
    }
    .knom_active__selection-like{
      fill: var(--color-1);
    }
  }
  a{
    color: var(--color-14);
  }
}
  &-like{
    fill: var(--color-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.knom_Sidebar{
    flex-direction: column;
    display: flex;
    width: 300px;
  
  &_list{
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }
  & ul{
    font-weight: 700;
    margin-bottom: 0;
  }
  .knom_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--color-10);
    &_logo{
      a{
        color: var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--white-color);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--white-color) !important;
    &:hover {
      background: var(--color-10) !important;
      color: var(--white-color) !important;
    }
  }

}


