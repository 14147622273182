:root{
    --color-1: #17252a;
    --color-2: #3aafa9;
    --color-3: #3aafa9;
    --color-4: #fff;
    --color-5: #2e151b;
    --color-6: #a5b4cf;
    --color-7: #2b7a78;


    --color-8: #fac87c;
    --color-10: var(--color-background);
    --background: #edeef0;
    
    --color-14: #fff;
    --white-color: #fff;
}

